import React, { useEffect } from 'react'
import { useRouter } from '@core/navigation'
import parse from 'html-react-parser'

/* Styles */
import ContentPageStyles from './ContentPage.module.scss'

/* Components */
import NotFound from '@components/page/NotFound'
import PageLoading from '@components/page/PageLoading'

/* Helpers */
import { getEncodedEmails } from '@helpers/emailHelper'
import { isKnownSFRA } from '@helpers/sfraHelper'

/* Hooks */
import { setClearScroll } from '@hooks/useRestoreScroll'

/* Local Types */
export interface contentType {
	online?: boolean
	name?: string
	body?: string
	css?: string
	js?: string
}
interface contentProps {
	content?: contentType | null
	allowEmpty?: boolean
	id?: string
	svg?: {
		[key: string]: React.ReactElement
	}
}
interface rawContentProps {
	body: string | undefined
	id: string | undefined
	css: string | undefined
	js: string | undefined
	allowEmpty: boolean | undefined
	svg:
		| {
				[key: string]: React.ReactElement
		  }
		| undefined
}

/* Local Components */
const rawContent = ({
	body,
	id,
	css,
	js,
	allowEmpty,
	svg,
}: rawContentProps): React.ReactElement => {
	const router = useRouter()

	/* Can mutate SVGs if svg is passed and at least 1 svg found in content? */
	const canMutate: boolean =
		svg && body && body.indexOf('.svg') > -1 ? true : false

	/* Keep parsed relative links inside router */
	useEffect(() => {
		const getHref = (e: Event): string => {
			const elem = e?.target as HTMLElement
			if (elem) {
				return (
					elem.getAttribute('href') ||
					elem.closest('a')?.getAttribute('href') ||
					''
				)
			}
			return ''
		}

		const links = document.querySelectorAll(`#ns-asset-${id} a`)
		if (links) {
			links.forEach((link) => {
				const href: string =
					link
						?.getAttribute('href')
						?.trim()
						.replace(window?.location?.origin, '') || ''

				if (href && href.startsWith('/') && !isKnownSFRA(href)) {
					if (!link.hasAttribute('target')) {
						link.addEventListener('click', (e: Event) => {
							if (e && e.target) {
								const clickedHref: string = getHref(e)
								if (clickedHref) {
									e.preventDefault()
									setClearScroll(clickedHref)
									router.push(clickedHref)
								}
							}
						})
					}
				}
			})
		}
	}, [])

	/* Replace SVGs from local SVG Map? */
	const replaceSVGs = (children: React.ReactNode): React.ReactNode => {
		return React.Children.map(children, (child) => {
			if (React.isValidElement(child)) {
				if (child.type === 'img') {
					const filename = child.props?.src?.match(/\/([^\/]+)\.svg/)?.[1]
					if (svg && filename && filename in svg) {
						return svg[filename]
					} else {
						return child
					}
				}
				if (child.props.children) {
					return React.cloneElement(child, {
						children: replaceSVGs(child.props.children),
					} as React.PropsWithChildren<React.ReactElement>)
				}
			}
			return child
		})
	}

	/* Local Components */
	const articleChildren = (): React.ReactElement => {
		const html = parse(getEncodedEmails(body || ''))
		return (
			<>
				{css && <style>{css}</style>}
				{js && <script dangerouslySetInnerHTML={{ __html: js }}></script>}
				{canMutate ? replaceSVGs(html) : html}
			</>
		)
	}

	if (allowEmpty) {
		return (
			<div className={ContentPageStyles['app-div']} id={'ns-asset-' + id}>
				{articleChildren()}
			</div>
		)
	} else {
		return (
			<article
				className={ContentPageStyles['app-article']}
				id={'ns-asset-' + id}
			>
				{articleChildren()}
			</article>
		)
	}
}

const ContentPage = ({
	content,
	allowEmpty,
	id,
	svg,
}: contentProps): React.ReactElement | null => {
	if (!content) {
		return allowEmpty ? null : <PageLoading />
	} else {
		const { online, body, css, js } = content ?? {}
		if (online) {
			return rawContent({ body, id, css, js, allowEmpty, svg })
		} else if (allowEmpty) {
			return null
		} else {
			return <NotFound />
		}
	}
}

export default ContentPage
