import React from 'react'

/* Styles */
import ContentPageWrapperStyles from './ContentPageWrapper.module.scss'

const ContentPageWrapper = ({
	children,
}: {
	children: React.ReactNode
}): React.ReactElement => {
	return (
		<section className={ContentPageWrapperStyles['wrapper']}>
			{children}
		</section>
	)
}

export default ContentPageWrapper
