import React from 'react'

/* Template ID */
const template: string = 'about-us'

/* Fixed Config */
const pageName: string = 'About Us'

/* Components */
import MultipleContentAssets from '@components/page/MultipleContentAssets'
import Page, { pageMetasType } from '@components/page/Page'

/* Helpers */
import { getCache } from '@helpers/cacheHelper'
import {
	getMultipleContentAssets,
	getEmptyPage,
	DataWithAssets,
} from '@helpers/contentHelper'

/* Shared Types */
import {
	MultipleContentAssetServerProps,
	ResponseProps,
} from '@helpers/contentHelper/contentHelper.models'

/* Shared Styles */
import '@components/page/CustomerServiceHero.scss'
import '@components/page/CustomerServiceBody.scss'

/* Local Styles */
import './about-us.scss'

/* Client Side */
const AboutUs = ({
	isReady,
	assets,
}: MultipleContentAssetServerProps): React.ReactElement => {
	return (
		<Page pageMetas={assets?.content as pageMetasType}>
			<MultipleContentAssets
				template={template}
				pageName={pageName}
				isReady={isReady}
				assets={assets}
				crumbsSchema={true}
			/>
		</Page>
	)
}

/* Server Side Props */
AboutUs.getProps = async ({
	res,
	params,
}: ResponseProps): Promise<MultipleContentAssetServerProps> => {
	const { pageId } = params ?? {}

	const data = await getMultipleContentAssets(template, pageId)

	if (res) {
		const is404 = getEmptyPage(data as DataWithAssets)
		if (is404) {
			res.status(404)
			res.statusCode = 404
		}

		res.set(
			'Cache-Control',
			getCache(is404 ? '404' : pageName.replace(/ /g, ''))
		)
	}

	return data
}

/* Template Name */
AboutUs.getTemplateName = () => template

export default AboutUs
