import React from 'react'

import Image from '@core/image'

/* Components */
import { Btn } from '@components/Btn/Btn'
import BtnClient from '@components/Btn/BtnClient'

/* Helpers */
import { getResource } from '@helpers/resourcesHelper'
import { routeHome, routeContact } from '@helpers/routeHelper'

/* Styles */
import NotFoundStyles from './NotFound.module.scss'

/* Local Types */
interface NotFoundResourcesType {
	alt: React.ReactElement | string
	h2: React.ReactElement | string
	body: React.ReactElement | string
	btnHome: React.ReactElement | string
	btnContact: React.ReactElement | string
}
interface NotFoundImageType {
	img: {
		width: number
		height: number
		src: string
	}
}

/* Variable Config */
const NotFoundResources: NotFoundResourcesType = {
	alt: getResource('404', 'alt', `Not Found`),
	h2: getResource('404', 'h2', `404 Something went wrong.`),
	body: getResource(
		'404',
		'text',
		` We're sorry, we can't find the page you're looking for.`
	),
	btnContact: getResource('403', 'contact', `Contact Us`),
	btnHome: getResource('404', 'home', `Return to Home`),
}

/* Fixed Config */
const NotFoundImage: NotFoundImageType = {
	img: {
		width: 573,
		height: 314,
		src: `/image/not-found.png`,
	},
}

const NotFound = (): React.ReactElement => {
	const buttonActions: {
		[key: string]: string
	} = {
		btnHome: routeHome(),
		btnContact: routeContact(),
	}

	return (
		<>
			<section className={NotFoundStyles['fourohfour']}>
				<div className={NotFoundStyles['fourohfour__column']}>
					<Image
						className={NotFoundStyles['fourohfour__img']}
						alt={NotFoundResources['alt'] as string}
						{...NotFoundImage['img']}
					/>
				</div>
				<div className={NotFoundStyles['fourohfour__column']}>
					<div>
						<h2 className={NotFoundStyles['fourohfour__h2']}>
							{NotFoundResources['h2']}
						</h2>
						<div className={NotFoundStyles['fourohfour__body']}>
							{NotFoundResources['body']}
						</div>
					</div>
					<div className={NotFoundStyles['fourohfour__btns']}>
						<Btn
							id={`btnContact`}
							variant={`primary`}
							className={NotFoundStyles['fourohfour__btns-btn']}
						>
							{NotFoundResources['btnContact']}
						</Btn>
						<Btn
							id={`btnHome`}
							variant={`secondary`}
							className={NotFoundStyles['fourohfour__btns-btn']}
						>
							{NotFoundResources['btnHome']}
						</Btn>
					</div>
				</div>
			</section>
			<BtnClient actions={buttonActions} />
		</>
	)
}

export default NotFound
