/* Local Types */
interface cacheType {
	[key: string]: string
}
interface cachesType {
	[key: string]: cacheType
}

/* Blog Cache Timing */
export const blogCacheTiming: string = '300'

/* Local Helpers */
const cacheControl = (time: string): string => {
	return `public, must-revalidate, max-age=${time}`
}
const calculateHours = (hours: number): string => {
	return cacheControl(`${hours * 3600}`)
}

/* Cache Times */
const hourCache: string = calculateHours(1)
const dayCache: string = calculateHours(24)

/* Cache Segments */
const plpCache: cacheType = {
	brand: hourCache,
	family: hourCache,
	default: hourCache,
}
const allBrandsCache: cacheType = {
	default: dayCache,
}
const caches: cachesType = {
	404: {
		default: calculateHours(1 / 12),
	},

	/* API routes */
	api: {
		object: calculateHours(3),
		default: hourCache,
	},

	/* PLP routes */
	watches: plpCache,
	'pre-owned': plpCache,
	jewelry: plpCache,

	/* All Brands routes */
	'watches-all-brands': allBrandsCache,
	'pre-owned-all-brands': allBrandsCache,
	'jewelry-all-brands': allBrandsCache,

	/* Default */
	default: {
		default: hourCache,
	},
}

/* Named functions */
export const getCache = (
	key?: string,
	segment?: string,
	timeOverride?: string
): string => {
	/* Override from .env? */
	if (timeOverride) {
		return cacheControl(timeOverride)
	}

	/* Calculate from cache segments or defaults */
	const defaultValue: string = 'default'
	key = key || defaultValue
	segment = segment || defaultValue
	if (key in caches === false) {
		key = defaultValue
	}
	if (segment in caches[key] === false) {
		segment = defaultValue
	}
	return caches[key][segment]
}

export const getBlogCache = (data: object, isPreview?: boolean): object => {
	return Object.assign(data, {
		cache: isPreview
			? 'private, no-store'
			: 'public, max-age=' + blogCacheTiming,
		time: new Date().toISOString(),
	})
}
