/* Helpers */
import {
	getContentAssetBundle,
	getContentAsset,
} from '@helpers/ocapiCustomAPIHelper'

/* Shared Types */
import {
	MultipleContentAssetServerProps,
	SingleContentAsset,
} from '@helpers/contentHelper/contentHelper.models'

/* Get a single Asset on Server */
export const getSingleContentAsset = async (
	id: string
): Promise<MultipleContentAssetServerProps> => {
	return getContentAsset(id)
		.then((value) => {
			return {
				isReady: true,
				id: id,
				assets: {
					content: value as SingleContentAsset,
				},
			}
		})
		.catch(() => {
			return {
				isReady: true,
				id: id,
				assets: {},
			}
		})
}

/* Get All Assets on Server */
export const getMultipleContentAssets = async (
	template: string,
	pageId?: string
): Promise<MultipleContentAssetServerProps> => {
	return getContentAssetBundle(template, pageId)
		.then((value) => {
			return {
				isReady: true,
				id: template,
				assets: (value as { [key: string]: SingleContentAsset }) || {},
			}
		})
		.catch(() => {
			return {
				isReady: true,
				id: template,
				assets: {},
			}
		})
}

/* Check if page has no assets to display */
export type DataWithAssets = {
	assets: {
		[key: string]: {
			online?: boolean
		}
	}
}
export const getEmptyPage = (data: DataWithAssets): boolean => {
	let ret: boolean = true

	if (data) {
		ret = !Object.values(data?.assets ?? {}).some(
			(asset) => asset?.online === true
		)
	}

	return ret
}
