import React from 'react'

/* Components */
import SomethingWentWrong from '@components/page/SomethingWentWrong'

/* Helpers */
import { withPageCSS } from '@helpers/helmetHelper'

const FatalError = ({ pageName }: { pageName: string }): React.ReactElement => {
	console.error(`${pageName} Fatal`)
	return withPageCSS('something-went-wrong', <SomethingWentWrong />)
}

export default FatalError
