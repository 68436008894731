import React from 'react'
import { JsonLd } from 'react-schemaorg'
import { BreadcrumbList } from 'schema-dts'

/* Helpers */
import { routeHome, routeContentPage } from '@helpers/routeHelper'

/* Shared Types */
import { CrumbsType } from './Crumbs.models'

/* Local Types */
export interface CrumbsSchemaFromPageProps {
	pageName: string
	template: string
	expectedURL?: string
	extraCrumbs?: CrumbsType[]
}
const CrumbsSchemaFromPage = ({
	pageName,
	template,
	expectedURL,
	extraCrumbs,
}: CrumbsSchemaFromPageProps): React.ReactElement => {
	/* Build list of crumbs */
	const crumbs: CrumbsType[] = [{ name: 'Home', url: routeHome() }]
	if (extraCrumbs) {
		extraCrumbs.forEach((extraCrumbs) => {
			crumbs.push(extraCrumbs)
		})
	}
	crumbs.push({
		name: pageName,
		url: expectedURL || routeContentPage(template),
	})

	return (
		<JsonLd<BreadcrumbList>
			item={{
				'@context': 'https://schema.org',
				'@type': 'BreadcrumbList',
				itemListElement: crumbs.map(({ name, url }, index) => ({
					'@type': 'ListItem',
					position: index + 1,
					item: {
						'@id': url,
						name: name,
					},
				})),
			}}
		/>
	)
}

export default CrumbsSchemaFromPage
