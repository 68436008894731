import React from 'react'

/* Helpers */
import { withPageCSS } from '@helpers/helmetHelper'

/* Components */
import ContentPage, { contentType } from '@components/page/ContentPage'
import ContentPageWrapper from '@components/page/ContentPageWrapper'
import PageLoading from '@components/page/PageLoading'
import CrumbsSchemaFromPage from '@components/base/Crumbs/CrumbsSchemaFromPage'

/* Fatal Error */
import FatalError from '@components/page/FatalError'

/* Shared Types */
import { MultipleContentAssetClientProps } from '@helpers/contentHelper/contentHelper.models'

const MultipleContentAsset = ({
	template,
	isReady,
	assets,
	pageName,
	wrapAsset,
	crumbsSchema,
	expectedURL,
	extraCrumbs,
}: MultipleContentAssetClientProps): React.ReactElement => {
	/* No content? */
	const allAssetsEmpty: boolean =
		isReady && (!assets || Object.keys(assets).every((key) => !assets[key]))
			? true
			: false
	if (allAssetsEmpty) {
		return FatalError({ pageName })
	}

	return withPageCSS(
		template,
		!isReady ? (
			<PageLoading />
		) : (
			<ContentPageWrapper>
				{crumbsSchema && (
					<CrumbsSchemaFromPage
						pageName={pageName}
						template={template}
						expectedURL={expectedURL}
						extraCrumbs={extraCrumbs}
					/>
				)}
				{Object.keys(assets).map((key) => {
					const assetComponent = (
						<ContentPage
							content={assets[key] as contentType}
							allowEmpty={key !== 'content'}
							id={key}
							key={key}
						/>
					)
					if (wrapAsset) {
						return wrapAsset(key, assetComponent)
					} else {
						return assetComponent
					}
				})}
			</ContentPageWrapper>
		),
		null,
		!isReady
	)
}

export default MultipleContentAsset
